@import "../../styles/tokens";

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &__container {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 375px;
  }

  &__title-group {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $background-primary;
    padding: 16px;
    position: relative;
  }

  &__title {
    @extend %sub-title;
  }

  &__content {
    padding: 16px;
  }

  &__button {
    position: absolute;
    right: 16px;
    transform: translateX(-50%);
    font-weight: 700;
    color: #fff;
  }
}
