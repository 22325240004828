@import "../../styles/tokens";
$border-thickness: 3px;
.card {
  cursor: pointer;
  width: 56px;
  height: 84px;
  background: $background-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  position: relative;
  border: $border-thickness solid $border-primary;
  transition: all 0.2s ease-in-out;

  &__icon {
    $dimensions: 32px;
    width: $dimensions;
    height: $dimensions;
    pointer-events: none;

    & svg {
      fill: $icon-brand;
      pointer-events: none;
    }
  }

  &__text {
    @extend %sub-title;
    pointer-events: none;
  }

  &:hover {
    border: $border-thickness solid $border-brand;
  }
}

.suggested {
  $sides: 8px;
  $height: 10px;

  position: absolute;
  bottom: -$height + -4px;
  width: 0;
  height: 0;
  border-left: $sides solid transparent;
  border-right: $sides solid transparent;
  border-bottom: $height solid $icon-brand;
}

.selected {
  border: $border-thickness solid $border-brand;
}
