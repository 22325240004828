@import "./styles/tokens";

.app {
  margin: 0 auto;
  max-width: 375px;
  text-align: center;
  color: $text-brand;
  text-transform: uppercase;
}

.weapon {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 64px;
  &__title {
    @extend %sub-title;
    margin-bottom: 16px;
  }
  & ul {
    display: flex;
    justify-content: space-between;
  }
}

.history {
  margin-bottom: 32px;
  display: flex;
  gap: 16px;

  &__button {
    width: 50%;
  }
}
