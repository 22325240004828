@import "./primatives";

///////////////
//**COLOURS**//
///////////////

//VARIABLES
//TEXT
$text-brand: $red-50;
$text-primary: #000;
//ICONS
$icon-brand: $red-50;
//BACKGROUNDS
$background-brand: $red-50;
$background-primary: $grey-40;
//BORDERS
$border-brand: $red-50;
$border-primary: $grey-40;
//SYSTEM

////////////
//**TEXT**//
////////////
%sub-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -1px;
}

%headline {
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
}

///////////////
//**SPACING**//
///////////////
