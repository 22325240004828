@import "../../styles/tokens";

.history {
  margin-bottom: 32px;
  display: flex;
  gap: 16px;

  &__button {
    width: 50%;
  }
}
