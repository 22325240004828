@import "../../styles/tokens";

.button {
  cursor: pointer;
  border: none;
  background: $background-primary;

  &__icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    color: $text-brand;
  }

  &__text {
    @extend %sub-title;
    color: $text-brand;
    padding: 8px 12px;
  }
}
