@import "../../styles/tokens";

.score {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;

  &__control {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @extend %sub-title;
    margin-bottom: 4px;
  }

  &__amount {
    @extend %headline;
    margin-bottom: 8px;
  }

  &__input {
    @extend %headline;
    color: $text-brand;
    width: 200px;
    margin-bottom: 12px;
    text-align: center;
    border: none;
  }
}
