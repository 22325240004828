@import "../../styles/tokens";

.summon-modal {
  %modal-groups {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__summon-group {
    @extend %modal-groups;
  }

  &__summon-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__monster-group {
    @extend %modal-groups;
  }

  &__monster-title-group {
    & h5 {
      @extend %sub-title;
    }

    & p {
      @extend %headline;
      font-size: 32px;
    }
  }

  &__image-container {
    & img {
      $image-dimensions: 200px;
      width: $image-dimensions;
      height: $image-dimensions;
    }
  }

  &__monster-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &__stats-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__monster-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    & svg {
      $dimensions: 24px;
      width: $dimensions;
      height: $dimensions;
      fill: $icon-brand;
    }

    & p {
      @extend %headline;
      border: 3px solid $border-primary;
      padding: 4px 24px;
    }
  }

  &__monster-special {
    width: 100%;
    @extend %sub-title;
    border: 3px solid $border-primary;
    padding: 8px 16px;
    text-transform: none;
  }

  &__button {
    @extend %sub-title;
    color: $text-brand;
    padding: 12px 24px;
  }
}
