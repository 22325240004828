@import "../../styles/tokens";

.headline {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  &__tier {
    margin-bottom: 32px;
  }

  & h2 {
    @extend %sub-title;
  }

  &__count {
    @extend %headline;
  }
  &__bar-text {
    // font-weight: 700;
    // font-size: 14px;
    @extend %sub-title;
    margin-bottom: 4px;
  }

  &__bar {
    width: 100%;
    margin: 0 auto;
    height: 16px;
    background: $background-primary;
  }

  &__bar-fill {
    display: block;
    width: 50%;
    height: 100%;
    background: $background-brand;
  }
}
