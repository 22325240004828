@import "../../styles/tokens";

.weapon-set {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  margin-bottom: 64px;

  &__cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    @extend %sub-title;
  }
}
